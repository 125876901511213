import * as Sentry from "@sentry/vue";
import { AxiosError } from "axios";

import { messagesToIgnore } from "@/sentry/errorMessages";

export const beforeSend = (event: Sentry.Event, hint: Sentry.EventHint) => {
  const error = hint.originalException as Error | AxiosError | undefined;
  if (
    error?.message &&
    messagesToIgnore.some((m) => error.message.includes(m))
  ) {
    return null;
  }

  // We don't want any network errors to be sent to Sentry
  if (error?.name === "AxiosError") {
    return null;
  }

  return event;
};
